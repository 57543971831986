@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import './assets/fonts/fonts.scss';
@import './assets/icons/style.css';

@import './styles/app.scss';

// You can add global styles to this file, and also import other style files
