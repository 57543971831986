// Components
@import './components/et-atoms-input';
@import './components/arrows';
@import './components/material';

// Google places autocomplete placement
// sass-lint:disable no-important
body {
  .pac-container {
    &.pac-logo {
      z-index: 10000 !important;
      position: fixed !important;
    }
  }
}
